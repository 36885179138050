import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import logo from "./ldc-logo.svg";
import styles from "./Header.module.css";

function Header({ path }) {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const { title } = data.site.siteMetadata;
  const htmlTitle = `${title}${path ? ` | ${path}` : ""}`;

  return (
    <header className={styles.header}>
      <Helmet title={htmlTitle} />

      <nav>
        <Link to="/">
          <img src={logo} alt="LDC’s Logo" />
        </Link>
      </nav>
    </header>
  );
}

export default Header;
