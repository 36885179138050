import React from "react";
import { Link } from "gatsby";

import logo from "./ldc-logo.svg";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <footer className={styles.footer}>
      <span className={styles.logo}>
        <img src={logo} alt="LDC’s Logo" />
      </span>
      <span className={styles.contact}>
        <p><strong>LDC obrt za trgovinu, proizvodnju, usluge i prijevoz</strong></p>
        <p>Visočička 37, 10040 Zagreb, HR</p>
        <p><a href="https://goo.gl/maps/Wm4vePxk9T2WLzeb9">Pogledaj adresu na karti</a> <span role="img" aria-label="Map pin">📍</span></p>
      </span>
      <span className={styles.info}>
        <p>Ova web stranica ne koristi kolačiće. Vaši osobni podaci nigdje nisu pohranjeni, koristimo ih samo kako bismo vas kontaktirali u vezi vašeg upita.</p>
        <p><Link to="/contact">Kontaktirajte nas &rarr;</Link></p>
      </span>
    </footer>
  );
}

export default Footer;
